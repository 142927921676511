export class Scroll {

  constructor (navigation, navigationBtn, sectionsContainer, animatedElement, sectionNumber, logo) {
    this.navigation = navigation
    this.buttons = navigationBtn.children
    this.sections = sectionsContainer.children
    this.animatedElement = animatedElement
    this.sectionNumber = sectionNumber
    this.logo = logo
    this.checked = false
    this.top = []
    this.bottom = []
    this.currentItem = 0
    this.calcTop()
    this.calcBottom()
    this.init()
    if (window.innerWidth >= 900) {
      this.animatedElement.classList.add('waiting-scroll-animation')
    }
    window.addEventListener('resize', () => {
      this.calcTop()
      this.calcBottom()
    })
  }

  calcTop () {
    if (this.top.length > 0) {
      this.top = []
    }
    for (let i = 0; i < this.sections.length; i = i + 1) {
      this.top.push(this.sections[i].offsetTop - (this.navigation.offsetHeight + 100))
    }
    this.top[0] = 0
  }

  calcBottom () {
    if (this.bottom.length > 0) {
      this.bottom = []
    }
    for (let i = 0; i < this.sections.length; i = i + 1) {
      this.bottom.push(this.top[i] + window.outerHeight)
    }
  }

  init () {
    window.addEventListener('scroll', (e) => {
      e.preventDefault()
      for (let i = 0; i < this.sections.length; i++)
        if (this.scrollY() >= this.top[i] && this.scrollY() <= this.bottom[i]) {
          this.addActive(i)
        }
      return false
    })
    for (let i = 0; i < this.sections.length; i++) {
      this.buttons[i].addEventListener('click', (e) => {
        e.preventDefault()
        this.scrollTo(i)
        return false
      })
    }
    this.logo.addEventListener('click', (e) => {
      e.preventDefault()
      this.scrollTo(0)
      return false
    })
  }

  scrollTo (index) {
    window.scrollTo({
      top: this.top[index],
      left: 0,
      behavior: 'smooth'
    })
    this.addActive(index)
  }

  addActive (index) {
    if (this.checked === false && this.sectionNumber === index) {
      this.animation()
      this.checked = true
    }
    if (this.buttons[index].classList.contains('activated')) {
      return false
    } else {
      for (let i = 0; i < this.buttons.length; i++) {
        if (this.buttons[i].classList.contains('activated')) {
          this.buttons[i].classList.remove('activated')
        }
      }
      this.buttons[index].classList.add('activated')
    }
  }

  animation () {
    if (this.animatedElement.classList.contains('waiting-scroll-animation')) {
      this.animatedElement.classList.remove('waiting-scroll-animation')
      this.animatedElement.classList.add('scroll-animation')
    }
  }

  scrollY () {
    let supportPageOffset = window.pageXOffset !== undefined
    let isCSS1Compat = ((document.compatMode || '') === 'CSS1Compat')

    return supportPageOffset ? window.pageYOffset : isCSS1Compat ? document.documentElement.scrollTop : document.body.scrollTop
  }
}
