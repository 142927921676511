export class Slider {

  /**
   * @param {HTMLElement}element
   * @param {HTMLElement}container
   * @param {NodeListOf}content
   */
  constructor (element, container, content) {
    this.element = element
    this.container = container
    this.content = content
    this.children = [].slice.call(container.children)
    this.pagination = []
    this.buttons = []
    this.listener = false
    this.currentItem = 0
    if (window.innerWidth > 950) {
      this.setStyle()
      this.getPagination()
      this.appendPagination()
      this.init()
    }
    window.addEventListener('resize', this.resize.bind(this))

  }

  getPagination () {
    if (this.pagination.length === 0) {
      for (let i = 0; i < this.children.length; i++) {
        this.pagination.push(this.children[i].firstElementChild)
        this.children[i].removeChild(this.children[i].firstElementChild)
        this.getButtons(i)
      }
    }
  }

  getButtons (index) {
    this.buttons.push(this.pagination[index].firstElementChild)
    this.buttons[0].classList.add('activated')
  }

  appendPagination () {
    if (!this.sliderPagination) {
      this.sliderPagination = this.createDivWithClass('slider-pagination')
    }
    for (let i = 0; i < this.children.length; i++) {
      this.sliderPagination.appendChild(this.buttons[i])
    }
    if (this.element.firstElementChild !== this.sliderPagination) {
      this.element.insertBefore(this.sliderPagination, this.container)
    }
  }

  removePagination () {
    if (this.element.firstElementChild === this.sliderPagination) {
      this.element.removeChild(this.sliderPagination)
      for (let i = 0; i < this.children.length; i++) {
        this.pagination[i].appendChild(this.buttons[i])
        this.children[i].insertBefore(this.pagination[i], this.content[i])
      }
    }
  }

  resize () {
    this.setStyle()
    if (window.innerWidth <= 950) {
      this.removePagination()
    } else if (window.innerWidth > 950) {
      this.getPagination()
      this.appendPagination()
      this.goToItem(this.currentItem)
      if (this.listener === false) {
        this.init()
      }
    }
  }


  init () {
    this.setStyle()
    for (let i = 0; i < this.children.length; i = i + 1) {
      this.buttons[i].addEventListener('click', () => this.goToItem(i))
    }
    this.listener = true
  }

  setStyle () {
    if (window.innerWidth <= 950) {
      this.container.style.width = 100 + '%'
      this.container.style.transform = 'translate3d(0%, 0, 0)'
    } else if (window.innerWidth > 950) {
      this.container.style.width = (this.children.length * 100) + '%'
    }
  }

  goToItem (index) {
    this.addActive(this.buttons, index)
    this.currentItem = index
    let translateX = index * -100 / this.children.length
    this.container.style.transform = 'translate3d(' + translateX + '%, 0, 0)'
  }

  addActive (element, index) {
    if (element[index].classList.contains('activated')) {
      return false
    } else {
      for (let i = 0; i < element.length; i = i + 1) {
        if (element[i].classList.contains('activated')) {
          element[i].classList.remove('activated')
        }
      }
      element[index].classList.add('activated')
    }
  }

  /**
   * @param {string} className
   * @returns {HTMLElement}
   */
  createDivWithClass (className) {
    let div = document.createElement('div')
    div.setAttribute('class', className)
    return div
  }

}
