export class Menu {
  /**
   * @param {HTMLElement} menu
   * @param {HTMLElement} linkContainer
   */
  constructor (menu, linkContainer) {
    this.element = menu
    this.linkContainer = linkContainer
    this.container = this.createDivWithClass('menu-icon-container')
    this.icon = this.createDivWithClass('icon-burger')
    this.container.appendChild(this.icon)
    if (window.innerWidth < 900) {
      this.element.appendChild(this.container)
    }
    this.container.addEventListener('click', (e) => {
      e.preventDefault()
      this.icon.classList.toggle('activated')
      if (this.linkContainer.classList.contains('mobile')) {
        this.linkContainer.classList.remove('mobile')
      }
      this.linkContainer.classList.toggle('activated')
    })
    window.addEventListener('resize', this.resize.bind(this))
  }

  resize () {
    if (window.innerWidth < 900) {
      if(!this.element.contains(this.container)) {
        if (!this.linkContainer.classList.contains('activated')){
          this.linkContainer.classList.add('mobile')
        }
        this.element.appendChild(this.container)
      }
    } else if (this.element.contains(this.container)) {
      this.element.removeChild(this.container)
      if (this.linkContainer.classList.contains('mobile')) {
        this.linkContainer.classList.remove('mobile')
      }
    }
  }

  /**
   * @param {string} className
   * @returns {HTMLElement}
   */
  createDivWithClass(className) {
    let div = document.createElement('div')
    div.setAttribute('class', className)
    return div
  }

}
