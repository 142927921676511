import {Menu} from './Menu'
import {Tab} from './Tab'
import {Slider} from './Slider'
import {EmailValidator} from './EmailValidator'
import {Scroll} from './Scroll'

new Menu(document.querySelector('.menu'), document.querySelector('.menu-content'))
new Tab(document.querySelectorAll('.tab-btn'), document.querySelectorAll('.tab'))
new Slider(document.querySelector('#slider'),
  document.querySelector('.slider-container'),
  document.querySelectorAll('.slider-content'))

let formElt = {
  'name': document.querySelector('#name'),
  'email': document.querySelector('#email'),
  'phone': document.querySelector('#phone'),
  'message': document.querySelector('#message')
}

document.forms['contact'].addEventListener('submit', (e) => {
  e.preventDefault()
  let emailValidator = new EmailValidator(document.forms['contact'], document.querySelectorAll('.form-groupe'))
  emailValidator.text(formElt['name'], 3, 20)
  emailValidator.email(formElt['email'])
  emailValidator.phone(formElt['phone'])
  emailValidator.message(formElt['message'], 30, 500)
  let validate = emailValidator.getErreur()
  if (validate) {
    emailValidator.send(document.forms['contact'])
  }
  return false
})

new Scroll(document.querySelector('.menu'),
  document.querySelector('.menu-content'),
  document.querySelector('.container'),
  document.querySelector('.wait-animation'),
  1,
  document.querySelector('.nav-logo'))

