export class EmailValidator {
  constructor (form, parentsInput) {
    this.form = form
    this.resetError(parentsInput)
    this.emailRegex = /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i
    this.errorMessage = {
      text: null,
      email: null,
      phone: null,
      message: null
    }
  }

  resetError (parentsInput) {
    for (let i = 0; i < parentsInput.length; i++) {
      if (parentsInput[i].firstElementChild.classList.value === 'erroredfield') {
        parentsInput[i].children[1].classList.remove('errored')
        parentsInput[i].removeChild(parentsInput[i].firstElementChild)
      }
    }
    delete this.errorMessage
  }

  /**
   * @param {HTMLElement} inputEmail
   */
  email (inputEmail) {
    if (!this.emailRegex.test(String(inputEmail.value).toLowerCase())) {
      this.errorMessage.email = 'Merci de renseigner une adresse email valide.'
      inputEmail.classList.add('errored')
      this.showError(inputEmail, this.errorMessage.email, 'erroredfield')
    }
  }

  /**
   * @param {HTMLElement} inputMessage
   * @param {int} minLength
   * @param {int} maxLength
   */
  message (inputMessage, minLength, maxLength) {
    let message = inputMessage.value
    if (message.length < minLength) {
      this.errorMessage.message = 'Merci de renseigner un message avec minimum ' + minLength + ' caractères.'
      inputMessage.classList.add('errored')
      this.showError(inputMessage, this.errorMessage.message, 'erroredfield')
    } else if (message.length > maxLength) {
      this.errorMessage.message = 'Merci de renseigner un message avec maximum ' + maxLength + ' caractères.'
      inputMessage.classList.add('errored')
      this.showError(inputMessage, this.errorMessage.message, 'erroredfield')
    }

  }

  /**
   * @param {HTMLElement} inputText
   * @param {int} minLength
   * @param {int} maxLength
   */
  text (inputText, minLength, maxLength) {
    let text = inputText.value
    if (text.length < minLength) {
      this.errorMessage.text = 'Merci de renseigner un nom avec minimum ' + minLength + ' caractères.'
      inputText.classList.add('errored')
      this.showError(inputText, this.errorMessage.text, 'erroredfield')
    } else if (text.length > maxLength) {
      this.errorMessage.text = 'Merci de renseigner un nom avec maximum ' + maxLength + ' caractères.'
      inputText.classList.add('errored')
      this.showError(inputText, this.errorMessage.text, 'erroredfield')
    }

  }

  /**
   * @param {HTMLElement} inputPhone
   */
  phone (inputPhone) {
    let tel = inputPhone.value
    if (tel.length < 10) {
      this.errorMessage.phone = 'Merci de renseigner un numéro de téléphone valide.'
      inputPhone.classList.add('errored')
      this.showError(inputPhone, this.errorMessage.phone, 'erroredfield')
    } else if (tel.length > 10) {
      this.errorMessage.phone = 'Merci de renseigner un numéro de téléphone valide.'
      inputPhone.classList.add('errored')
      this.showError(inputPhone, this.errorMessage.phone, 'erroredfield')
    }
  }

  showError (field, message, className) {
    let div = this.createDivWithClass(className)
    div.innerHTML = '<span>' + message + '</span>'
    this.insertAfter(div, field)
  }

  getErreur () {
    return (typeof this.errorMessage.text === 'object' &&
      typeof this.errorMessage.email === 'object' &&
      typeof this.errorMessage.phone === 'object' &&
      typeof this.errorMessage.message === 'object')
  }

  send () {
    let data = new FormData(this.form)
    let xhr = new XMLHttpRequest()
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4 && xhr.status === 200) {
        if(xhr.response !== "Erreur") {
          if (this.form.firstElementChild.classList.value === 'success') {
            this.form.removeChild(this.form.firstElementChild)
          }
          this.getMessage(this.form, 'success', 'Votre message a bien été envoyé !')
        } else {
          if (this.form.firstElementChild.classList.value === 'error') {
            this.form.removeChild(this.form.firstElementChild)
          }
          this.getMessage(this.form, 'error', 'Un problème est survenu merci de vérifier les champs')
        }
      } else if (xhr.readyState === 4) {
        alert("Impossible d'envoyer votre message, merci d'essayer plus tard.")
      }
    }
    xhr.open('POST', 'main.php', true)
    xhr.responseType = 'json'
    xhr.send(data)
  }

  getMessage (element, className, message) {
    if (element.firstElementChild.classList.value !== className) {
      let div = this.createDivWithClass(className)
      div.innerHTML = '<span>' + message + '</span>'
      element.insertBefore(div, element.firstElementChild)
    }
  }

  createDivWithClass (className) {
    let div = document.createElement('div')
    div.setAttribute('class', className)
    return div
  }

  insertAfter (field, referenceNode) {
    referenceNode.parentNode.insertBefore(field, referenceNode)
  }
}
